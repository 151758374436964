<template>
  <div>
    <b-container fluid>
      <b-row class="tab-button-row" v-if="false">
        <b-col>
          <router-link :to="{ name: 'release-detail' }" tag="button" class="btn">Overzicht</router-link>
        </b-col>
        <b-col>
          <router-link :to="{ name: 'release-detail-edit' }" tag="button" class="btn">Bewerken</router-link>
        </b-col>
      </b-row>

      <b-row v-if="release == null && error == null" class="mt-5">
        <b-col class="text-center">
          <b-spinner label="Spinning"></b-spinner>
        </b-col>
      </b-row>

      <b-row v-if="error != null" class="mt-5">
        <b-col class="text-center">
          {{ error }}
        </b-col>
      </b-row>
    </b-container>

    <router-view  v-if="release != null" 
      :release="release" 
      @takedown="takedown"
      @save-outlet-settings="saveOutletSettings"
       />
  </div>
</template>

<script>
  export default {
    name: 'ReleaseDetailContainer',
    data () {
      return {
        release: null,
        error: null,
      }
    },
    props: {
      id: String
    },
    methods: {
      saveOutletSettings (settings) {
        this.release = null
        this.$http.put('albums/'+this.id, {
            Outlets: settings    
          })
          .then(response => {
            this.$router.push({ name: 'release-detail' })
            this.release = response.data
            this.$notify({
              type: 'success',
              text: 'Portalkeuze opgeslagen'
            });
          })
          .catch((error) => {
            this.$notify({
              type: 'danger',
              text: 'Er ging iets mis bij het opslaan: ' + error.response.data
            });
          });
      },
      takedown (reason) {
        this.release = null
        this.$http.put('albums/'+this.id, {
            ProductDetails: { DeliveryStatus: 83 },
            Reason: reason
          })
          .then(response => {
            this.release = response.data
            this.$notify({
              type: 'success',
              text: 'Release wordt ingetrokken'
            })
          })
          .catch((error) => {
            console.log(error)
            this.error = 'Er is iets misgegaan, probeer het later nog eens'
            this.$notify({
              type: 'warning',
              text: 'Er is iets misgegaan, probeer het later nog eens'
            })
          }) 
      }
    },
    mounted () {
      this.$http.get('albums/'+this.id, {
          
        })
        .then(response => {
          this.release = response.data
        })
        .catch((error) => {
          console.log(error)
        });
    }
  }
</script>